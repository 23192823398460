var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "tableRisk",
            attrs: {
              title: "LBL0001203 ",
              tableId: "riskRegister01",
              columns: _vm.gridRisk.columns,
              data: _vm.riskReduce.riskbooks,
              merge: _vm.gridRisk.merge,
              gridHeight: _vm.setheight,
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                      ? _vm._l(
                          _vm.$comm.ibmTagItems(props.row),
                          function (item, index) {
                            return _c(
                              "q-chip",
                              {
                                key: index,
                                staticStyle: {
                                  "margin-bottom": "4px !important",
                                },
                                attrs: {
                                  outline: "",
                                  square: "",
                                  color: item.color,
                                  clickable: true,
                                  "text-color": "white",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.linkClick(item, props.row)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(item.title) + " "),
                                _c("c-assess-tooltip", {
                                  attrs: {
                                    ibmTooltip: item.ibmTooltip,
                                    ibmClassCd: item.ibmClassCd,
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        )
                      : void 0,
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "tableImpr",
            attrs: {
              title: "LBL0001174",
              tableId: "impr01",
              columns: _vm.gridImpr.columns,
              data: _vm.riskReduce.imprs,
              merge: _vm.gridImpr.merge,
              gridHeight: _vm.setheight,
            },
            on: { linkClick: _vm.linkClick },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }